"use client";

import * as React from "react";

import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { LoaderCircle } from "lucide-react";
import { Button } from "~/components/common/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { cn } from "~/lib/utils";
import { api } from "~/utils/api";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSendLinkAction: (email: string) => void;
}

export function UserAuthForm({
  className,
  onSendLinkAction,
  ...props
}: UserAuthFormProps) {
  const supabase = createPagesBrowserClient();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<string | null>(null);

  const checkUserValid = api.auth.checkUserValid.useMutation();

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!email) {
        setError("Email is required");
        return;
      }

      setError(null);

      // check user exist or invited
      const { valid } = await checkUserValid.mutateAsync({ email });

      if (!valid) {
        setError(
          "We couldn't find your email address in our records. Please check the email address and try again.",
        );
        return;
      }

      if (valid) {
        const emailRedirectTo = `${window.location.origin}/login`;
        const { data, error } = await supabase.auth.signInWithOtp({
          email,
          options: {
            emailRedirectTo,
            shouldCreateUser: true,
          },
        });

        if (error) {
          setError(error.message);
        } else {
          console.log(`muly:UserAuthForm`, { data, emailRedirectTo });
          onSendLinkAction(email);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          {!!error && <label className="text-critical">{error}</label>}
          <Button loading={isLoading} disabled={isLoading}>
            Send Magic Link
          </Button>
        </div>
      </form>
    </div>
  );
}
