import { useRouter } from "next/router";
import type { Session } from "@supabase/auth-helpers-nextjs";
import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { useEffect, useState } from "react";
import { api } from "~/utils/api";
import { useConfigContext } from "~/components/common/config/config-context";
import { Loading } from "~/components/common/Loading";
import { LoginPage } from "~/components/authentication/LoginPage";

export default function AuthenticationPage() {
  const router = useRouter();
  const supabase = createPagesBrowserClient();
  const upsertUserProfile = api.auth.upsertUserProfile.useMutation();
  const { userProfile } = useConfigContext();
  const [isReady, setIsReady] = useState(false);

  console.log(`muly:AuthenticationPage`, {
    q: router.query,
    userProfile,
  });

  const redirectAfterLogin = async () => {
    const { redirectedFrom } = router.query;
    if (redirectedFrom) {
      console.log(`muly:redirectAfterLogin redirect ${redirectedFrom}`, {
        userProfile,
      });
      await router.replace(redirectedFrom as string);
    } else {
      console.log(`muly:redirectAfterLogin redirect /`, {
        userProfile,
      });
      await router.replace("/");
    }
  };

  const onAuth = async (sessionClient: Session | null) => {
    if (!userProfile) {
      if (!upsertUserProfile.isPending) {
        await upsertUserProfile.mutateAsync();
        await redirectAfterLogin();
      } else {
        console.log(`muly:onAuth upsertUserProfile.isLoading skip`);
      }
    } else {
      await redirectAfterLogin();
    }
  };

  useEffect(
    () => {
      const authListener = supabase.auth.onAuthStateChange(
        (event, sessionClient) => {
          if (sessionClient?.user?.id) {
            onAuth(sessionClient).catch((err) => {
              console.error(`muly:onAuthStateChange:onAuth:error`, err);
            });
          } else {
            setIsReady(true);
          }

          if (event === "SIGNED_IN") {
            console.log("User signed in");
            void router.replace("/");
          } else if (event === "SIGNED_OUT") {
            console.log("User signed out");
          }
        },
      );

      // Clean up the listener when the component unmounts
      return () => {
        authListener.data.subscription.unsubscribe();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!isReady) {
    return <Loading />;
  }

  return <LoginPage />;
}

AuthenticationPage.disableLayout = true;
AuthenticationPage.disableNeedUser = true;
AuthenticationPage.disableNeedOrganizationData = true;
