import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { UserAuthForm } from "~/components/authentication/user-auth-form";
import { Layout } from "~/components/onboarding/Layout";

export const LoginPage = () => {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | undefined>(
    router.query.error_description
      ? String(router.query.error_description)
      : undefined,
  );

  return (
    <Layout>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-80">
          {!email && (
            <>
              <div className="flex flex-col space-y-4 text-center">
                <h1 className="text-3xl font-semibold tracking-tight">
                  Get Started
                </h1>
                <p className="text-sm font-medium">
                  Enter your email below to login or create an account
                </p>
              </div>
              <UserAuthForm onSendLinkAction={setEmail} />
              {error && <p className="text-center text-critical">{error}</p>}
              <p className="px-8 text-center text-sm">
                By clicking "Send Magic Link", you indicate that you have read,
                understood and agree to our{" "}
                <Link
                  target="_blank"
                  href="/terms"
                  className="underline underline-offset-4 hover:text-black"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  target="_blank"
                  href="/privacy"
                  className="underline underline-offset-4 hover:text-black"
                >
                  Privacy Policy
                </Link>
                .
              </p>
            </>
          )}
          {!!email && (
            <div className="text-center">
              <p className="text-lg font-semibold">Check your email</p>
              <p className="text-sm text-light3">
                We've sent a magic link to {email}. Click the link to continue.
              </p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
