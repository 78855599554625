import { Logo } from "~/components/layout/Logo";

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props) => {
  return (
    <div className="container h-screen max-h-screen py-3">
      <div className="grid h-full grid-cols-1 gap-4 py-10 md:grid-cols-2 md:py-0">
        <div className="m-auto flex max-w-sm flex-col items-center">
          <Logo />
          {children}
        </div>
        <div className="hidden h-full items-center rounded-xl bg-blue-600 px-10 md:flex">
          <div>
            <p className="mb-16 text-center text-3xl font-medium text-white">
              Welcome to Cube9
            </p>
            <img src="/LoginImage.png" className="mx-auto max-w-full" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
